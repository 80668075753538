<div class="login-container">
  <div class="col login-overlay-container">
    <div class="login-overlay">
      <div class="logo-container">
        <!-- <h3>Bayshore Health Care</h3> -->
        <img [src]="'icon-bayshore-white.svg'|assetUrl" alt="Logo" class="logo-icon">
      </div>
      <div class="project-details">
        <h3 class="project-title" [ngClass]="{'tablet':isTablet}">Remote Patient Monitoring</h3>
        <span>Version 2.0</span>
      </div>
    </div>
    <img [src]="'Mask Group 1-2x.webp'| assetUrl" class="login-col-img">
  </div>
  <div class="col">
    <button mat-raised-button color="primary" class="rpm-button" (click)="login()">Login </button>
  </div>
</div>