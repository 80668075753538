import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { filter, Subscription, takeUntil, Subject } from 'rxjs';
import '@zonejs-patch';
import {
  MsalBroadcastService,
  MsalService,
} from '@azure/msal-angular';
import {
  EventMessage,
  AuthenticationResult,
  EventType,
  InteractionStatus,
} from '@azure/msal-browser';

@Component({
  selector: 'authentication-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'authentication-app';
  authSubscription!: Subscription;
  unsubscribe: Subject<void> = new Subject();
  isProcessing = true;
  spinnerProps = { strokeWidth: 5.5, diameter: 50 }

  constructor(
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {

    localStorage.removeItem("redirect_from_login");
    this.route.fragment.subscribe(() => {
      const fragment = this.route.snapshot.fragment;
      if (fragment?.includes('state=') && !fragment?.includes('error=server_error')) {
        // #R2-962 - handled login success event
        this.isProcessing = true;
      }
    });
    this.route.queryParams.subscribe(param => {
      localStorage.removeItem('REDIRECT_URL');
      if (param['redirectUrl']) localStorage.setItem('REDIRECT_URL', decodeURIComponent(param['redirectUrl']));
    });

    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            msg.eventType === EventType.SSO_SILENT_SUCCESS
        ),
        takeUntil(this.unsubscribe)
      )
      .subscribe((result: EventMessage) => {
        let payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
        if (payload) {
          this.redirectToDashboardOrPatientSummary();
        }
        // #R2-962 - handled isProcessing for login success event
        this.isProcessing = false;
      });
    
      //#R2-1453 - Fixed login redirection issue 
      this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this.unsubscribe)
      )
      .subscribe((event) => {
        this.isProcessing = false;
      });

    let activeAccount = this.authService.instance.getActiveAccount();
    if (this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
      if (accounts.length > 0) {
        this.redirectToDashboardOrPatientSummary();
      }
    }
  }

  private redirectToDashboardOrPatientSummary(): void {
    localStorage.setItem("redirect_from_login", "true");
    const patientIdToSummaryPage = localStorage.getItem('patientIdToSummaryPage');
    const redirectToSummary = localStorage.getItem('redirectToSummary');
    const redirectUrl = localStorage.getItem('REDIRECT_URL');
    if (patientIdToSummaryPage && redirectToSummary === 'true') {
      localStorage.removeItem('patientIdToSummaryPage');
      localStorage.removeItem('redirectToSummary');
      this.router.navigateByUrl(`patient/${patientIdToSummaryPage}/summary`);
      return;
    }
    if (redirectUrl) {
      try {
        new URL(redirectUrl);
        localStorage.removeItem('REDIRECT_URL');
        location.href = redirectUrl;
        return;
      } catch (err) {
        localStorage.removeItem('REDIRECT_URL');
      }
    }
    this.router.navigate(['/dashboard']);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
