import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';

import { NgModule } from '@angular/core';

const MaterialModules = [
  MatButtonModule,
  MatProgressSpinnerModule
]

@NgModule({
  declarations: [],
  imports: [MaterialModules],
  exports: [MaterialModules]
})
export class MaterialModule { }
