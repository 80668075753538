import {
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
} from '@azure/msal-angular';
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { RedirectRequest, InteractionStatus } from '@azure/msal-browser';
import { Subject, filter, takeUntil } from 'rxjs';
import { BreakpointService } from '../services/breakpoint.service';
import { ORG_PERSIST_KEY } from 'src/config/app.config';

@Component({
  selector: 'authentication-app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  isUserLoggedIn: boolean = false;
  private unsubscribe = new Subject<void>();
  isTablet: boolean = false;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalBroadCastService: MsalBroadcastService,
    private authService: MsalService,
    private breakpointService: BreakpointService
  ) { }

  ngOnInit(): void {
    localStorage.removeItem(ORG_PERSIST_KEY);
    this.breakpointService.isTablet$.pipe(
      takeUntil(this.unsubscribe)
    ).subscribe((tablet: boolean) => {
      this.isTablet = tablet;
    });

    this.msalBroadCastService.inProgress$
      .pipe(
        filter(
          (interactionStatus: InteractionStatus) =>
            interactionStatus == InteractionStatus.None
        ),
        takeUntil(this.unsubscribe)
      )
      .subscribe((x) => {
        this.isUserLoggedIn =
          this.authService.instance.getAllAccounts().length > 0;
      });
    // this.login();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  login() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }
}
