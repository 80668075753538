import '@zonejs-patch';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import {
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
} from '@azure/msal-angular';
import { InteractionType } from '@azure/msal-browser';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MaterialModule } from './material/material.module';
import { AssetUrlPipe } from './pipes/asset-url.pipe';
import { getMsalInstance } from '@bayshoreHealthCare/store';
import { SentryErrorHandlerService } from './services/sentry-error-handler.service';

const msalInstanceGlobal = getMsalInstance();

@NgModule({
  declarations: [AppComponent, EmptyRouteComponent, LoginComponent, AssetUrlPipe],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    ReactiveFormsModule,
    MsalModule.forRoot(msalInstanceGlobal, {
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: [],
        },
      },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([
          ['https://graph.microsoft.com/v1.0/me', []],
        ]),
      }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    MsalGuard,
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandlerService,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
