import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SentryService {

  private sentry: any;

  constructor() {
    this.sentry = (window as any).Sentry;
  }

  getSentry() {
    return this.sentry;
  }
}
