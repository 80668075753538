import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { distinctUntilChanged, Subject, takeUntil, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BreakpointService {
  isTablet: boolean = false;
  isTablet$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  unsubscribe: Subject<void> = new Subject();

  constructor(
    private breakpointObserver: BreakpointObserver
  ) {
    this.breakpointObserver.observe(
      [Breakpoints.Tablet, Breakpoints.Web, Breakpoints.Large, Breakpoints.XLarge]
    )
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.unsubscribe)
      ).subscribe(() => {
        if (this.breakpointObserver.isMatched(Breakpoints.Tablet) || this.breakpointObserver.isMatched(Breakpoints.Web)) {
          this.isTablet$.next(true)
        }
        if (this.breakpointObserver.isMatched(Breakpoints.Large) || this.breakpointObserver.isMatched(Breakpoints.XLarge)) {
          this.isTablet$.next(false);
        }
      });
  }
}
